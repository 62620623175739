import React from 'react'
import './Intro.css'

class Intro extends React.Component {
  render () {
    return (
      <div className="intro-area">
        <div className="intro-container">
          <h1>Andrea Bender</h1>
          <p>
            Software developer, avid climber, Jeopardy enthusiast, 
            and plant lover. Always happy to connect for work 
            opportunites!
          </p>
        </div>
      </div>
    )
  }
}


export default Intro;