import React from 'react'
import { Link } from 'react-router-dom'
import quizapp from '../../assets/sunny-img.jpeg'
import dearbeer from '../../assets/bg2.png'
import quendi from '../../assets/bg3.png'
import venga from '../../assets/bg4.png'
import './Portfolio.css'


class Portfolio extends React.Component {
  render() {
    return(
      <div className='portfolio-container'>
        <div className='portfolio-project'>
          <img src={dearbeer} alt ='project 1' className='image'></img>
          <div className="overlay">
            <div className="text"><Link to='/dearbeer'>DearBeer</Link></div>
          </div>
        </div>
        <div className='portfolio-project'>
          <img src={quendi} alt='project 2' className='image'></img>
          <div className="overlay">
            <div className="text"><Link to='/quendi'>Quendi</Link></div>
          </div>
        </div>
        <div className='portfolio-project'>
          <img src={venga} alt='project 3' className='image'></img>
          <div className="overlay">
            <div className="text"><Link to='/venga'>Venga</Link></div>
          </div>
        </div>
        <div className='portfolio-project'>
          <img src={quizapp} alt='project 4' className='image'></img>
          <div className="overlay">
            <div className="text"><Link to='/quizapp'>Quiz App</Link></div>
          </div>
        </div>
        {/* <div className='portfolio-project'>
          <div className='project-container'>
            <img src={db} alt='project 4' className='project-img'></img>
            <div className="overlay">
            <div className="text"><Link to='/quizapp'>Quiz App</Link></div>
          </div>
          </div>
        </div> */}
        
      </div>
    )
  }
}

export default Portfolio