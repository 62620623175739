import React from 'react'
import dearbeer from '../../assets/bg2.png'
import NavBar from '../NavBar/NavBar'
import db1 from '../../assets/db1.png'
import db2 from '../../assets/db2.png'
import './DearBeer.css'

class DearBeer extends React.Component {
  render() {
    return (
      <div className='db-page'>
        <NavBar />
        <section className='container'>
          <figure>
            <img src={dearbeer} alt ='project 1' className='db-image'></img>
          </figure>
        
          <div className='db-wrapper'>
            <h2>DearBeer</h2>
            <p>
              Is a functional user-friendly app that encourages users to keep a log of beers they've tried.
              DearBeer provides a form where users can describe the beer they've had and say  
              whether they liked or disliked it. After a few beers it may be hard to remember 
              whether you liked the beer you had that day, but don't worry DeerBeer has your back.
            </p>
          </div>

          <figure className='db-img'>
            <img src={db1} alt ='screenshot of app' className='screenshot'></img>
            <figcaption>Image of DearBeer dashboard displaying saved beers</figcaption>
          </figure>

          <div className='db-wrapper'>
            <p>
              The frontend of this app was built using React, HTML, CSS, and JS. The dashboard allows
              users to search for beers they've added by their names. They can also sort based on rating,
              color, type of beer, and heaviness (alcohol taste). The design we chose was rustic to reflect the hops and 
              barrels involved when brewing beer. This was written with test-driven development using enzyme, 
              smoke tests and creating snapshots. 
            </p>
          </div>

          <figure className='db-img'>
            <img src={db2} alt ='screenshot of app' className='screenshot'></img>
            <figcaption>Image of bars and breweries in Boulder, CO.</figcaption>
          </figure>

          <div className='db-wrapper'>
            <p>
              DearBeer allows a user to search for breweries and bars around them showing 
              them where the nearest place to grab a drink is. The map depicts places in the 
              US based on a zipcode input. It tells the user the location, rating, number, 
              and if it's open or closed. From the map, a user is also able to click a place 
              and add a beer to their list for that location. This was done by implemeting Google's maps API.
            </p>
          </div>
  
          <div id="btn-container">
            <a className='app-link' href="https://dearbeer.now.sh/" target="_blank" rel="noopener noreferrer"> 
              <button className="live-site">
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                </span>
                <span className="button-text">View Site</span>
              </button>
            </a>
          </div>

          <h3 className='tech-header'>Technology Stack</h3>
          <div className='tech-stack'>
            <div className='frontend'>
              <h4>Front-End</h4>
              <li>React</li>
              <li>JavaScript</li>
              <li>HTML & CSS</li>
              <li>Zeit</li>
            </div>
            <div className='backend'>
              <h4>Back-End</h4>
              <li>Node.js</li>
              <li>Express.js</li>
              <li>postgreSQL</li>
              <li>Heroku</li>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default DearBeer
