import React from 'react'
import me1 from '../../assets/me1.jpeg'
import me2 from '../../assets/me2.jpg'
import me3 from '../../assets/arches.jpg'
import me4 from '../../assets/thekind.JPG'

import './AboutMe.css'

class AboutMe extends React.Component {
  render () {
    return (
      <section id='about-wrapper'>
        <div className='about'>
          <h3>Hi there!</h3>
          <p>
            I am a software developer that is passionate about turning data into action. I try to keep a mindful
            and fierce approach to test-driven development so that users can have the best experience possible. 
            I love creating modern/minimalistic designs, but am always open to experimenting and trying new 
            techniques that push me outside of my box.
          </p>
          <p>
            My background in biology has taught me that with any experiment there will be a lot of failure and 
            successes, but the drive and perseverance through those experiences make the final result feel like 
            a greater accomplishment. From working in a parasitic manipulation lab, the Forest Service, and a top 
            ten craft brewery, I have learned how to adapt and work in teams in a variety of settings. I am ready 
            to bring my passion and drive for success and share it with a like-minded team of programmers!
          </p>
          <p>
            Born and raised in West Palm Beach, FL, but currently based in Boulder, CO. When I’m not coding, I love
            climbing in the alpine, listening to music, reading about divisive issues that shape our society, and 
            adventuring to new places.
          </p>
          <p>If you would like to connect for work feel free to <a href='mailto:andreab.1726@gmail.com' aria-label="Andrea Bender's email" target='_blank' rel="noopener noreferrer">contact me</a>!</p>

          {/* <button className='resume-btn'>
          <a href="../../public/Bender_Resume.pdf" download="newfilename">Download Resume</a>        
          </button> */}
        </div>

        <div className='wrapper'>
          <figure>
            <img src={me1} alt ='andrea bender' className='img-small'></img>
            <figcaption className='about-caption'>Devil's Bridge Sedona, AZ</figcaption>
          </figure>
          <figure>
            <img src={me4} alt ='andrea bender' className='img-tall'></img>
            <figcaption className='about-caption'>Rocky Mountain National Park, CO</figcaption>
          </figure>
          <figure>
            <img src={me2} alt ='andrea bender' className='img-small'></img>
            <figcaption className='about-caption'>USFS Mount Evans,CO</figcaption>
          </figure>
          <figure>
            <img src={me3} alt ='andrea bender' className='img-small'></img>
            <figcaption className='about-caption'>Arches National Park, UT</figcaption>
          </figure>
        </div>
      </section>
    )
  } 
}
  
  


export default AboutMe;