import React, { Component } from 'react'
import venga from '../../assets/bg4.png'
import v1 from '../../assets/v1.png'
import './Venga.css'
import NavBar from '../NavBar/NavBar'

export class Venga extends Component {
  render() {
    return (
      <div className='venga-page'>
      <NavBar />
      <section className='container'>

        <figure>
          <img src={venga} alt ='project 1' className='venga-image'></img>
        </figure>

        <div className='venga-wrapper'>
          <h2>Venga</h2>
          <p>
          Venga is a user friendly application designed for rock climbers to share information 
          on different climbs throughout the world. With the number of climbs constantly increasing,
           it can be hard to know what all is out there. 
          </p>
        </div>

        <figure className='venga-img'>
          <img src={v1} alt ='screenshot of app' className='screenshot'></img>
        </figure> 

        <div className='venga-wrapper'>
          <p>
          This app brings the rock climbing community together by allowing anyone to share new climbs.
           It allows you to add the name, location, description, grade, style of climb, and rate how 
           much you enjoyed it. So keep climbing on!
          </p>
        </div>

        


        <div id="btn-container">
        <a href="https://venga.now.sh/" target="_blank" rel="noopener noreferrer">         
        <button className="live-site">
            <span className="circle" aria-hidden="true">
              <span className="icon arrow"></span>
            </span>
            <span className="button-text">View Site</span>
          </button>
        </a>
        </div>

        <h3 className='tech-header'>Technology Stack</h3>
          <div className='tech-stack'>
            <div className='frontend'>
              <h4>Front-End</h4>
              <li>React</li>
              <li>JavaScript</li>
              <li>HTML & CSS</li>
              <li>Zeit</li>
            </div>
            <div className='backend'>
              <h4>Back-End</h4>
              <li>Node.js</li>
              <li>Express.js</li>
              <li>postgreSQL</li>
              <li>Heroku</li>
            </div>
          </div>

      
      </section>
      </div>
    )
  }
}

export default Venga
