import React from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'

class NavBar extends React.Component {
  render() {
    return (
      <div className='navbar-area'>
        <div className='container'>
          <nav className='site-navbar'>
            <Link to='/' className="site-logo" alt='Andrea Bender logo'>Andrea Bender</Link>

            {/* Toggle Menu */}
            {/* <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label> */}
            <ul className="menu">
              <li><Link to='/' id='portfolio'>Portfolio</Link></li>
              <li><Link to='/about'>About</Link></li>
            </ul>
            
            
          </nav>
        </div>
      </div>
    )
  }
  
}

export default NavBar