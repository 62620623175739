import React from 'react'
import NavBar from '../NavBar/NavBar'
import AboutMe from '../AboutMe/AboutMe'
import Socials from '../Socials/Socials'

class AboutPage extends React.Component {
  render () {
    return (
      <div className= 'about-page'>
        <NavBar />
        <AboutMe />
        <Socials />
      </div>
    )
  }
}

export default AboutPage