import React from 'react';
import Portfolio from '../Portfolio/Portfolio';
import Socials from '../Socials/Socials';
import Header from '../Header/Header';
// import './MainPage.css';

class MainPage extends React.Component {
  render () {
    return (
      <div>
        <Header />
        <Portfolio />
        <Socials />
      </div>
    )
  }
}

export default MainPage;
