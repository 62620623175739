import React from 'react'
import './Footer.css'

class Footer extends React.Component {
  render () {
    return (
      <footer>
        All materials © Andrea Bender 2020
      </footer>
    )
  }
}

export default Footer;