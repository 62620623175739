import React from 'react'
import quendi from '../../assets/bg3.png'
import q1 from '../../assets/q1.png'
import q2 from '../../assets/q2.png'
import './Quendi.css'
import NavBar from '../NavBar/NavBar'

class Quendi extends React.Component {
  render() {
    return (
      <div className='quendi-page'>
        <NavBar />
      <section className='container'>
        <figure>
          <img src={quendi} alt ='project 1' className='quendi-image'></img>
        </figure>

        <div className='quendi-wrapper'>
          <h2>Quendi</h2>
          <p>
            <em>Aiya, mellons!</em> Quendi is a creative app that aims to teach users the fictional Elvish 
            language from the Lord of The Rings books written by J.R.R. Tolkien. Quendi translates to 
            "The Speakers", which I believe is a fitting name for the users who will become Elvish speakers
            by utilizing this app.
           </p>
        </div> 

        <figure className='quendi-img'>
          <img src={q1} alt ='screenshot of app' className='screenshot'></img>
        </figure>
        
        <div className='quendi-wrapper'>
          <p>
            This app functions by using spaced repetition in order to effectively help users learn the 
            Elvish language. Initially, the user is shown a screen with all the vocabulary words they 
            will be learning with their english translations. Afterwards, they can start to practce 
            one word at a time with a text input box to ensure spelling as well.
          </p>
        </div>
        
      <figure className='quendi-img'>
        <img src={q2} alt ='screenshot of app' className='screenshot'></img>
      </figure>

      <div className='quendi-wrapper'>
        <p>
          Quendi will keep track of the correct and incorrect answers throughout the learning 
          process. It will continously show the user the words they are getting incorrect more 
          frequently than those they have easily learned. The vocabulary for this project includes 
          both Sindarin and Quenyan dialects. Have fun learning, I bid you all <em>Quel re</em>!
        </p>
      </div>

      

      <div id="btn-container">
        <a href="https://quendi.app/" target="_blank" rel="noopener noreferrer">
          <button className="live-site">
            <span className="circle" aria-hidden="true">
              <span className="icon arrow"></span>
            </span>
            <span className="button-text">View Site</span>
          </button>
        </a>
        </div> 
        

        <h3 className='tech-header'>Technology Stack</h3>
          <div className='tech-stack'>
            <div className='frontend'>
              <h4>Front-End</h4>
              <li>React</li>
              <li>JavaScript</li>
              <li>HTML & CSS</li>
              <li>Zeit</li>
            </div>
            <div className='backend'>
              <h4>Back-End</h4>
              <li>Node.js</li>
              <li>Express.js</li>
              <li>postgreSQL</li>
              <li>Heroku</li>
            </div>
          </div>
        
      </section>
      </div>
    )
  }
}

export default Quendi

