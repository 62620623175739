import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './App.css'

import MainPage from './components/MainPage/MainPage'
// import AboutMe from './components/AboutMe/AboutMe';
import DearBeer from './components/DearBeer/DearBeer'
import Quendi from './components/Quendi/Quendi'
import Venga from './components/Venga/Venga'
import QuizApp from './components/QuizApp/QuizApp'

// import Contact from './components/Contact/Contact'
// import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import AboutPage from './components/AboutPage/AboutPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

export default class App extends Component {
  render(){
    return(
      <Router>
        <ScrollToTop />
      <div className='App'>
        {/* <Header /> */}
          
            <Route exact path='/'  component={MainPage} />
            <Route exact path='/about'  component={AboutPage} />
            {/* <Route exact path='/contact'  component={Contact} /> */}
            <Route exact path='/dearbeer'  component={DearBeer} />
            <Route exact path='/quendi'  component={Quendi} />
            <Route exact path='/venga'  component={Venga} />
            <Route exact path='/quizapp'  component={QuizApp} />

        <Footer />
      </div>
      </Router>
    )
  }
}
