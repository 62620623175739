import React from 'react'
import quizapp from '../../assets/sunny-img.jpeg'
import NavBar from '../NavBar/NavBar'
import quiz1 from '../../assets/quiz1.png'
import quiz2 from '../../assets/quiz2.png'
import './QuizApp.css'

class QuizApp extends React.Component {
  render() {
    return (
      <div className='quiz-page'>
        <NavBar />
        <section className='container'>
          <figure>
            <img src={quizapp} alt ='project 1' className='quiz-image'></img>
          </figure>
        
          <div className='quiz-wrapper'>
            <h2>Quiz App</h2>
            <p>
              Is a fun app created to practice using jQuery. The theme is based on the TV
              show It's Always Sunny in Philidelphia. 
            </p>
          </div>

          <figure className='quiz-img'>
            <img src={quiz1} alt ='screenshot of app' className='screenshot'></img>
            <figcaption>Image of questions display</figcaption>
          </figure>

          <div className='quiz-wrapper'>
            {/* <p>
              The frontend of this app was built using React, HTML, CSS, and JS. The dashboard allows
              users to search for beers they've added by their names. They can also sort based on rating,
              color, type of beer, and heaviness (alcohol taste). The design we chose was rustic to reflect the hops and 
              barrels involved when brewing beer. This was written with test-driven development using enzyme, 
              smoke tests and creating snapshots. 
            </p> */}
          </div>

          <figure className='quiz-img'>
            <img src={quiz2} alt ='screenshot of app' className='screenshot'></img>
            <figcaption>Feedback based on answer submitted</figcaption>
          </figure>

          <div className='quiz-wrapper'>
            {/* <p>
              DearBeer allows a user to search for breweries and bars around them showing 
              them where the nearest place to grab a drink is. The map depicts places in the 
              US based on a zipcode input. It tells the user the location, rating, number, 
              and if it's open or closed. From the map, a user is also able to click a place 
              and add a beer to their list for that location. This was done by implemeting Google's maps API.
            </p> */}
          </div>
  
          <div id="btn-container">
            <a className='app-link' href="https://andrea-bender.github.io/quiz-app/" target="_blank" rel="noopener noreferrer"> 
              <button className="live-site">
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                </span>
                <span className="button-text">View Site</span>
              </button>
            </a>
          </div>

          <h3 className='tech-header'>Technology Stack</h3>
          <div className='tech-stack'>
            <div className='frontend'>
              <h4>Front-End</h4>
              <li>jQuery</li>
              <li>JavaScript</li>
              <li>HTML & CSS</li>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default QuizApp;
