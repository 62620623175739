import React from 'react'
import NavBar from '../NavBar/NavBar'
import Intro from '../Intro/Intro'
// import './Header.css';

class Header extends React.Component {
  render () {
    return (
      <header className="header-area">
        <NavBar />
        <Intro />
      </header>
    )
  }
}
  
export default Header